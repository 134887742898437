













import Vue from 'vue';

export default Vue.extend({
  computed: {
  },
  methods: {
    closeGuidedTour() {
      this.$emit('setShowGuidedTour', false);
    },
  },
});
