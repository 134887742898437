

















import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
    useBorder: {
      type: Boolean,
      required: false,
      default: true,
    },
    marginTop: {
      type: Number,
      required: false,
      default: 40,
    },
    marginAuto: {
      type: Boolean,
      required: false,
      default: false,
    },
    height: {
      type: Number,
      required: false,
      default: 100,
    },
    width: {
      type: Number,
      required: false,
      default: 100,
    },
    txtBottom: {
      type: Number,
      required: false,
      default: -80,
    },
    txtRight: {
      type: Number,
      required: false,
      default: 0,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
    txtLeft: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
});
