






import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    openGuidedTour() {
      this.$emit('setShowGuidedTour', true);
    },
  },
});
